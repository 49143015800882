$(function () {
  //navbar bg on window scroll
  if ($(window).scrollTop() > 200) {
    $(".nav-bar").addClass("coloredBG");
    $(".logo").addClass("show");
  }

  $(window).scroll(function scrolledNavStyles() {
    if ($(window).scrollTop() > 120) {
      $(".nav-bar").addClass("coloredBG");
      $(".logo").addClass("show");
    } else {
      $(".nav-bar").removeClass("coloredBG");
      $(".logo").removeClass("show");
    }
  });
  //navbar bg on window scroll // end

  var $myCarousel = $("header.carousel");
  // Initialize carousel
  $myCarousel.carousel({
    interval: 5000,
  });
  //*****************************//
  // ** slider for reviews box **
  var $myCarousel2 = $("#bottom-slider");
  // Initialize carousel
  $myCarousel2.carousel({
    interval: 5000,
  });

  //*****************************//
  // slider on genitori page
  var $myCarousel2 = $("#genitori-slider");
  // Initialize carousel
  $myCarousel2.carousel({
    interval: 5000,
  });

  $myCarousel2.bind("slid.bs.carousel", function (e) {
    var indx = $("ol.carousel-indicators li.active").data("slide-to");
    $(".genitori .box-slider a").each(function () {
      $(this).removeClass("focused");
    });
    $('div.slider-console a[data-target="#genitori-slider"][data-slide-to=' + indx + "]").addClass("focused");
  });

  var $myCarousel3 = $("#insegnanti-slider");
  // Initialize carousel
  $myCarousel3.carousel({
    interval: 5000,
  });
  //*****************************//
  // slider on genitori page
  var $myCarouselBlog = $("#blog-slider");
  // Initialize carousel
  $myCarouselBlog.carousel({
    interval: 5000,
  });
  //*****************************//
  // slider on insegnanti page
  // Instantiate the Bootstrap carousel
  $(".multi-item-carousel").carousel({
    interval: false,
  });
  // for every slide in carousel, copy the next slide's item in the slide.
  // Do the same for the next, next item.
  $(".multi-item-carousel .item").each(function () {
    var next = $(this).next();
    if (!next.length) {
      next = $(this).siblings(":first");
    }
    next.children(":first-child").clone().appendTo($(this));

    if (next.next().length > 0) {
      next.next().children(":first-child").clone().appendTo($(this));
    } else {
      $(this).siblings(":first").children(":first-child").clone().appendTo($(this));
    }
  });

  // Select all links with hashes
  $('a.scrollAnimation[href*="#"]')
    // Remove links that don't actually link to anything
    .not('[href="#"]')
    .not('[href="#0"]')
    .click(function (event) {
      // On-page links
      if (location.pathname.replace(/^\//, "") == this.pathname.replace(/^\//, "") && location.hostname == this.hostname) {
        // Figure out element to scroll to
        var target = $(this.hash);
        target = target.length ? target : $("[name=" + this.hash.slice(1) + "]");
        // Does a scroll target exist?
        if (target.length) {
          // Only prevent default if animation is actually gonna happen
          event.preventDefault();
          $("html, body").animate(
            {
              scrollTop: target.offset().top,
            },
            1000,
            function () {
              // Callback after animation
              // Must change focus!
              var $target = $(target);
              $target.focus();
              if ($target.is(":focus")) {
                // Checking if the target was focused
                return false;
              } else {
                $target.attr("tabindex", "-1"); // Adding tabindex for elements not focusable
                $target.focus(); // Set focus again
              }
            }
          );
        }
      }
    });
});

//*********************************************************************//
//**** header carousel scripts for text and buttons animations ****//
//*********************************************************************//
// slider on the homepage

$(function () {
  $("#reg_teacher_form").submit(function () {
    return validate(this);
  });

  if ($("#countainer_schools").children().length == 1) {
    $(".remove_school_link").css("display", "none");
  }
});

// Is `n` a number or numeric string (eg "3")?
window.isNumeric = function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

// Pad `n` with `z` (or `0` by default) to `width`.
window.zeroPad = function zeroPad(n, width, z) {
  z = z || "0";
  n = n + "";
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

window.toggle_visibility = function toggle_visibility(id) {
  var e = document.getElementById(id);
  if (e.style.display != "none") e.style.display = "none";
  else e.style.display = "block";
}

$('[data-purpose="fill-dropdown"]').change();

window.isOdd = function isOdd(value) {
  if (value % 2 != 0) return true;
  else return false;
}

window.update_panel_school_subjects = function update_panel_school_subjects(me) {
  var summary = $("#school_subject_summary").text();
  var selected = $(me).text();
  $("#school_subject_summary").text((summary += selected));
}

$(document).on("click", 'input[data-purpose="fill_summary_school_subjects"]', function () {
  $(".panel_checkboxes").css("display", "block");
  var $checked = $("input:checked");
  var texts = $checked.map(function (i, el) {
    return $(el).closest("label").find("span").html();
  });
  $("#school_subject_summary").text($.makeArray(texts).join(", "));
});

window.trim = function trim(s) {
  if (s != null) {
    s = s.replace(/(^\s*)|(\s*$)/gi, "");
    s = s.replace(/[ ]{2,}/gi, " ");
    s = s.replace(/\n /, "\n");
    return s;
  }
}

$("#areadropdown")
  .change(function (e) {
    var container1 = document.getElementById("container_school_subjects_1");
    var container2 = document.getElementById("container_school_subjects_2");
    $("#school_subject_summary").text("");
    container1.innerHTML = "";
    container2.innerHTML = "";
    var selected = this.value.split("-");
    var term = trim(selected[0]);
    var area = trim(selected[1]);
    var query = "/profile/school-subject?term=" + term;
    if (area != null) {
      query += "&area=" + area;
    }
    $.getJSON(query, function (schoolSubjects) {
      for (var i = 0; i < schoolSubjects.length; i++) {
        var obj = schoolSubjects[i];
        var target = container1;
        if (isOdd(i)) target = container2;

        var content = "<div>";
        content += '<input class="input_checkbox_school_type" id="cb' + i + '" name="user[school_subjects][]" type="checkbox" value="' + obj.id + '">';
        content += '<label class="checktype form-control label_checkbox_school_type" for="cb' + i + '">' + obj.name + "</label>";
        content += "</div>";

        $(content).appendTo(target);
      }
    });
  })
  .change();

// $(function (){
//   var pickerOpts = {
//     monthNames: ['Gennaio','Febbraio','Marzo','Aprile','Maggio','Giugno',
//             'Luglio','Agosto','Settembre','Ottobre','Novembre','Dicembre'],
//     monthNamesShort: ['Gen','Feb','Mar','Apr','Mag','Giu',
//             'Lug','Ago','Set','Ott','Nov','Dic'],
//     dayNames: ['Domenica','Luned&#236','Marted&#236','Mercoled&#236','Gioved&#236','Venerd&#236','Sabato'],
//     dayNamesShort: ['Dom','Lun','Mar','Mer','Gio','Ven','Sab'],
//     dayNamesMin: ['Do','Lu','Ma','Me','Gi','Ve','Sa'],
//     prevText: '&#x3c;Prec', prevStatus: '',
//     prevJumpText: '&#x3c;&#x3c;', prevJumpStatus: '',
//     nextText: 'Succ&#x3e;', nextStatus: '',
//     nextJumpText: '&#x3e;&#x3e;', nextJumpStatus: '',
//     currentText: 'Oggi', currentStatus: '',
//     todayText: 'Oggi', todayStatus: '',
//     clearText: '-', clearStatus: '',
//     closeText: 'Chiudi', closeStatus: '',
//     yearStatus: '', monthStatus: '',
//     weekText: 'Sm', weekStatus: '',
//     dayStatus: 'DD d MM',
//     defaultStatus: '',
//     dateFormat: 'dd/mm/yy',
//     firstDay: 1,
//     isRTL: false,
//     showButtonPanel: false,
//     changeMonth: true,
//     changeYear: true,
//     yearRange: '1920:2020',
//     defaultDate: "1/1/1980"
//   };
//   $("#profile_birthdate").datepicker(pickerOpts);

// });

window.toTitleCase = function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

$(function () {
  $(document).on("click", ".mail_miur", function () {
    $.each($(".dynamic_section :input"), function (idx, v) {
      $(v).val(null);
    });
  });
});

$(function () {
  $.each($('[data-purpose="open-modal"]'), function () {
    $("#post_reg_popup").foundation("reveal", "open");
  });
});

$(function () {
  var searchStyle = $('[data-purpose="virtual_class_search_style"]').get(0);
  if ($('[data-purpose="virtual_class_search"]').length) {
    $('[data-purpose="virtual_class_search"]')
      .get(0)
      .addEventListener("input", function () {
        if (!this.value) {
          searchStyle.innerHTML = "";
          return;
        }
        searchStyle.innerHTML = '.searchable:not([data-index*="' + this.value.toLowerCase() + '"]) { display: none; }';
      });
  }
});

window.update_school_mail = function update_school_mail() {
  $("#school_subject_summary").text((summary += selected));
}

$(document).on("change", '[data-purpose="virtual_class_jump"]', function () {
  document.location.href = this.options[this.selectedIndex].value;
});

var hide_show_others = window.hide_show_others = function (event) {
  $(".show_hide_others").toggle();
  content = $(".show_hide_others_link").html().trim() == "Mostra altre della tua scuola:" ? "Nascondi altre della tua scuola:" : "Mostra altre della tua scuola:";
  return $(".show_hide_others_link").html(content);
};

$(document).on("ready page:load", function () {
  return $(document).on("click", ".show_hide_others_link", hide_show_others);
});

$(document).on("click", "a", function (e) {
  if (this.pathname === window.location.pathname && /Modal$/.test(this.hash) && $(this.hash).length) {
    e.preventDefault();
    $(this.hash).foundation("reveal", "open");
  }
});

$(function () {
  if (/Modal$/.test(window.location.hash) && $(window.location.hash).length) {
    $(window.location.hash).foundation("reveal", "open");
  }
});

// $(function () {
//   $(".with_smooth_scroll").smoothScroll();
// });

$(document).on("click", ".hide_strip_button", function hideButton() {
  $(".containg_strip").css("visibility", "hidden");
});

$(document).on("change", '[data-purpose="render_modal_activation"]', function () {
  var brandId = $('[data-purpose="render_modal_activation"]').val();
  var divToShow = ["#modal_activation_for_brand_id_", brandId].join("");
  $("[id^=modal_activation_for_brand_id_]").hide();
  $(divToShow).show();
});

$("#user_pwd").capsChecker({
  capson: function (e, isOn) {
    $(".registrazione_pw span.capsLockMessage").html("MAIUSC BLOCK attivato");
  },
  capsoff: function (e, isOn) {
    $(".registrazione_pw span.capsLockMessage").html("");
  },
});

$(document).on("click", '[data-purpose="with-book-stats"]', function (e) {
  e.preventDefault();
  var query = this.href;
  $.getJSON(query, function (data) {
    var source = $("#bookStatModalTemplate").html();
    var template = Handlebars.compile(source);
    var html = template(data);
    $("#bookStatModal").html(html).foundation("reveal", "open");
  });
});

$(document).on("change", 'select[data-purpose="hideOrShowSchool"]', function (e) {
  if (e.target.value == "teacher") {
    $(".hide_or_show_school").show();
  } else {
    $(".hide_or_show_school").hide();
  }
});

$(document).on("click", "#open_drop_for_login_menu img", function (e) {
  e.preventDefault();
  Foundation.libs.dropdown.open($("#drop_for_login_menu"), $("#open_drop_for_login_menu"));
  return false;
});

$(document).on("click", '[data-purpose="showJsonHelp"]', function (e) {
  e.preventDefault();
  $("#help_for_json").toggle();
  $('[data-purpose="showJsonHelp"]').text($("#help_for_json").is(":visible") ? "Nascondi" : "Mostra esempio");
  return false;
});

$(document).on("click", '[data-purpose="changeWelcomeSlideshow"]', function (a) {
  $("[class^=welcome_slideshow_content_panel_activate]").removeClass("block");
  var target = $(this).attr("data-target");
  var divToShow = [".welcome_slideshow_content_panel_", target].join("");
  var cellToActivate = [".welcome_cell_", target].join("");
  $("[class^=welcome_slideshow_content_panel_]").hide();
  $("[class^=welcome_cell_]").removeClass("welcome_selected_cell");
  $(divToShow).show();
  $(cellToActivate).addClass("welcome_selected_cell");
  window.location.hash = target;
  return false;
});

$(function () {
  var welcomeTarget = window.location.hash.substring(1);
  if (welcomeTarget && /activate|downloadapp|downloadbook|profile/.test(welcomeTarget)) {
    $("[class^=welcome_slideshow_content_panel_]").hide();
    $(".welcome_slideshow_content_panel_" + welcomeTarget).show();
    $("[class^=welcome_cell_]").removeClass("welcome_selected_cell");
    $([".welcome_cell_", welcomeTarget].join("")).addClass("welcome_selected_cell");
  }
});

$(function () {
  $("img[usemap]").rwdImageMaps();
});

$(document).on("click", ".slide_carousel", function (a) {
  var slideNumber = $(this).attr("data-orbit-slide-number");
  $("#welcomeExerciseModal").foundation("reveal", "open");
  $("#exerciseOrbit")
    .siblings(".orbit-bullets")
    .find("[data-orbit-slide='" + slideNumber + "']")
    .click();
});

$(document).on("click", "#welcomeExerciseModal a.close-reveal-modal", function (a) {
  $("#welcomeExerciseModal").foundation("reveal", "close");
});

$(document).on("click", ".clearing-blackout", function (e) {
  // $('a.clearing-close').trigger('click');
});
