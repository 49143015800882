import "parsleyjs/src/parsley.js";
import "parsleyjs/src/i18n/fr.js";
import "parsleyjs/src/i18n/es.js";
import "parsleyjs/src/i18n/it.js";
import "parsleyjs/src/i18n/de.js";
import "jquery-ui/dist/jquery-ui.js";
import "@hotwired/turbo-rails";
// import "jquery-smooth-scroll/jquery.smooth-scroll.js";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "moment/src/moment.js";
import "moment/src/locale/de.js";
import "moment/src/locale/es.js";
import "moment/src/locale/fr.js";
import "moment/src/locale/it.js";
// import "flexslider/jquery.flexslider.js";
import "@vendor/jquery.rwdImageMaps.js";
import "@vendor/jquery.capsChecker.js";
import "@vendor/handlebars-v1.2.0";
import "bootstrap-select";

import "./login_signup.js";
import "./school_select.js";
// import "./jquery.flexslider.js"; // why another flexslider?
import "./events.js";
import "./custom.js";
import "./tutors/custom.js";
import "./tableau/custom.js";

$(function () {
  $('[data-toggle="tooltip"]').tooltip();
  // $(".chosen-select").chosen({
  //   width: "300px",
  //   allow_single_deselect: true,
  //   placeholder_text_single: select_option,
  //   no_results_text: no_results_text,
  // });

  $(function () {
    setTimeout(function () {
      $(".alert").slideUp(500);
    }, 5000);
  });

  $("select[name='filter_for_publisher_select']").on("change", function () {
    var url = $(this).val();
    if (url) {
      // require a URL
      window.location = url; // redirect
    }
    return false;
  });

  // Required to trigger validation when step 1 is
  // completely autofilled with oauth data
  $("#user_name").trigger("change");

  var userLang = sessionLang || navigator.language || navigator.userLanguage;
  console.log("User language is:", userLang);

  userLang = userLang.substring(0, 2).toLowerCase();
  if (!["en", "fr"].includes(userLang)) {
    userLang = "en";
  }
  window.Parsley.setLocale(userLang);

  window.Parsley.addValidator('rejectPattern', {
    requirementType: 'string',
    validateString: function(value, requirement) {
      // check that the email value does not contain http or // via regex
      var regex = new RegExp(requirement);
      return !regex.test(value);
    },
  });

  window.Parsley.addValidator("rejectEmoji", {
    // requirementType: "string",
    validateString: function (value) {
      var regex = new RegExp(
        /[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}]/gu
      );
      return !regex.test(value);
    },
  });

  window.Parsley.addAsyncValidator(
    "emailUnique",
    function (xhr) {
      // Ritorna true anche se la richiesta non funziona per far
      // comunque andare avanti l'utente.
      // La validazione della mail c'è comunque alla fine del form.
      return xhr.status !== 200 || !xhr.responseJSON.found;
    },
    "/api/v5/users/email/check?email={value}"
  );

});
