$(function () {

  $('.role-select-trigger').click(function() {
    var wrapper = $(this).closest('.user-role-select-form')
    var role = $(this).data('role');
    $(wrapper).find('#user_role_select_form_role').val(role);


    if (role === 'teacher') {
      if ( $(this).data('locale') === 'it') {
        $(wrapper).find('.first-step').hide();
        $(wrapper).find('.second-step').show();
        $(wrapper).find('.birthday-select').hide();
        $(wrapper).find('.users-select-school-teacher-subtitle').show();
        $(wrapper).find('.users-select-school-student-subtitle').hide();
      } else {
        $(wrapper).find('.btn-submit').trigger('click');
      }

    } else {
      $(wrapper).find('.users-select-school-teacher-subtitle').hide();
      $(wrapper).find('.users-select-school-student-subtitle').show();
      $(wrapper).find('.first-step').hide();
      $(wrapper).find('.second-step').show();
    };

    var birthdayOK = true;
    var schoolOK = true;


    $(wrapper).find('.birthday-select select').on('change', function() {
      var complete = true;
      var count = $(wrapper).find('.birthday-select select').size();
      var index = 0;
      birthdayOK = true;
      $(wrapper).find('.birthday-select select').each(function(i, obj) {
        var val = $(this).val();
        if (val === '') {
          index++;
          complete = false;
        }
      });
      if (!complete && index !== count && index !== 0) {
        // console.warn("Birthday is NOT OK");
        birthdayOK = false;
      }

      checkBDayAndSchool();
    });

    $(wrapper).find('.school-select input.to-complete').on('change', function() {
      var complete = true;
      var count = $(wrapper).find('.school-select input.to-complete').size();
      var index = 0;
      schoolOK = true;
      $(wrapper).find('.school-select input.to-complete').each(function(i, obj) {
        var val = $(this).val();
        if (val === '') {
          index++;
          complete = false;
        }
      });
      if (!complete && index !== count && index !== 0) {
        schoolOK = false;
      }

      checkBDayAndSchool();
    });

    var checkBDayAndSchool = function() {
      if (birthdayOK && schoolOK) {
        // console.log("Everything is fine!");
        $(wrapper).find('.btn-submit').attr("disabled",false);
      } else {
        // console.warn("Something is NOT OK");
        $(wrapper).find('.btn-submit').attr("disabled",true);
      }
    };
  });

});
