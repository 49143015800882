// get served country list
var servedCountryList = (window.servedCountryList = function () {
  var countryList;
  $.ajax({
    url: "/school_data/countries_with_regions_list",
    dataType: "json",
    async: false,
    success: function (data) {
      countryList = data;
    },
  });
  return countryList;
});

// function thet gets the list of coutries from endpoint '/school_data/countries_with_regions_list' and returns it

$(document).on("change focusout", '[data-purpose="fill-dropdown"]', function (e) {
  var input = $(this).closest(".dynamic_section").find("input")[1];
  $.each($('[data-purpose="auto-school"]'), function (idx, v) {
    setAutocompleteSchool($(v));
  });
});

$(function () {
  $(".user-school-id").on("change", function () {
    if ($(".user-school-id").val() == "custom_school") {
      displayCustomSchool($(".trigger-show-custom-school-name"));
      showSchoolSecondaryFields();
      $(".back-to-sel-school").show();
    } else {
      showSchoolSecondaryFields();
    }
  });
});

$(function () {
  $.each($('[data-purpose="fill-dropdown"]'), function (idx, v) {
    setAutocompleteRegion($(v));
  });
  var city = $(".user-school-region");
  city.attr("autocomplete", "none");
});

// --- START school select functions ----

var schoolNationChange = (window.schoolNationChange = function (param) {
  var $this = $(param);
  var container = $this.parents(".user-school-inputs");
  var nation = container.find(".user-school-nation");
  var cityContainer = container.find(".user-school-region-container");
  var city = container.find(".user-school-region");
  var cityOptions = container.find(".user-school-region option");
  var cityOptionsOther = container.find(".user-school-region option[data-country!='" + $this.val() + "']");
  var sel = container.find(".user-school-id");
  var customSchoolName = container.find(".custom-school-name");
  var customUserClass = container.find(".custom-user-class");
  var schools = container.find(".school-name");

  // clear fields
  city.val("");
  customSchoolName.val("");
  customUserClass.val("");
  sel.find("option").filter(".deletable").remove();
  schools.hide();
  hideCustomSchool(container.find(".trigger-show-custom-school-name"));

  // show city if supported
  // if Nation not supported list available schools or fill school name manually
  // nation.val() is the country id selcted
  if (nation.val() !== "" && servedCountryList().includes(parseInt(nation.val()))) {
    fillRegions(city, nation.val());
    cityOptions.show();
    // Hide city of other states
    cityOptionsOther.hide();
    cityContainer.show();
    // container.find('.ask-for-custom-school').show();
  } else {
    showSchoolSecondaryFields();
    displayCustomSchool(param);
    cityContainer.hide();
    city.val("-ANY-");
    $(".back-to-sel-school").hide();
    // container.find('.ask-for-custom-school').hide();
  }
});

var clearSelectSchool = (window.clearSelectSchool = function (param) {
  var $this = $(param);
  var container = $this.parents(".user-school-inputs");
  var sel = container.find(".user-school-id");
  var userClass = container.find(".user-class");
  sel.selectedIndex = 0;
  userClass.val("");
});

var clearCustomSchool = (window.clearCustomSchool = function (param) {
  var $this = $(param);
  var container = $this.parents(".user-school-inputs");
  var customSchoolName = container.find("#user_schools_attributes_name");
  var customUserClass = container.find(".custom-user-class input");
  customSchoolName.val("");
  customUserClass.val("");
});

var clearChosenSchool = (window.clearChosenSchool = function (param) {
  clearSelectSchool(param);
  clearCustomSchool(param);
});

var schoolCityChange = (window.schoolCityChange = function (param) {
  clearChosenSchool(param);
  hideCustomSchool($(".trigger-show-custom-school-name"));
  var $this = $(param);
  var container = $this.parents(".user-school-inputs");
  var nation = container.find(".user-school-nation").val();
  var region_id = container.find(".user-school-region").val();
  var sel = container.find(".user-school-id");
  var schools = container.find(".school-name");
  fillSchools(sel, region_id);
});

// var triggerShowCustomSchoolName = function(param) {
//   clearChosenSchool(param);
//   var $this = $(param);
//   var container = $this.parents('.user-school-inputs');
//   container.children('.trigger-show-custom-school-name').each(function() {
//     $this.toggle();
//   });
//   container.find('.school-name').toggle();
//   container.find('.custom-school-name').toggle();
//   select school from existing
//   if (container.find('.user-school-id').is(":hidden")) {
//     container.find('.user-school-id').attr('required', null);
//     container.find('.user-school-region').attr('required', null);
//     container.find('.user-school-region').val('');
//     container.find('.user-school-region-container').hide();
//     container.find('#user_schools_attributes_name').attr('required', 'required');
//   } else { // custom school
//     container.find('.user-school-id').attr('required', 'required');
//     container.find('.user-school-region').attr('required', 'required');
//     container.find('.user-school-region-container').show();
//     container.find('#user_schools_attributes_name').attr('required', null);
//   };
//   container.find('.custom-user-class').toggle();
//   container.find('.user-class').toggle();

//   manageDisableBtn($this.parents('.user-school-inputs'), 2);
// };

var displayCustomSchool = (window.displayCustomSchool = function (param) {
  var $this = $(param);
  var container = $this.parents(".user-school-inputs");
  clearChosenSchool(param);
  container.find(".school-name").hide();
  // container.find(".user-school-region-container").hide();
  container.find(".custom-school-name").show();
  //fix required
  // container.find(".user-school-id").attr("required", null);
  container.find(".user-school-region").attr("required", null);
  // container.find(".user-school-region").val("-ANY-");
  // container.find(".user-school-region-container").hide();
  container.find("#user_schools_attributes_name").attr("required", "required");
});

var hideCustomSchool = (window.hideCustomSchool = function (param) {
  clearChosenSchool(param);
  var $this = $(param);
  var container = $this.parents(".user-school-inputs");
  // container.find('.school-name').show();
  container.find(".custom-school-name").hide();
  // fix required
  container.find(".user-school-id").attr("required", "required");
  container.find(".user-school-region").attr("required", "required");
  container.find(".user-school-region-container").show();
  container.find("#user_schools_attributes_name").attr("required", null);
});

window.backToSelSchool = function backToSelSchool(param) {
  hideCustomSchool($(".trigger-show-custom-school-name"));
  $(".school-name").show();
};

window.showSchoolSecondaryFields = function showSchoolSecondaryFields(param) {
  $(".user-school-class-container").show();
  $(".user-school-position-container").show();
  $(".user-school-subject-container").show();
};

window.hideSchoolSecondaryFields = function hideSchoolSecondaryFields(param) {
  $(".user-school-class-container").hide();
  $(".user-school-position-container").hide();
  $(".user-school-subject-container").hide();
};

var addSchool = (window.addSchool = function () {
  if ($("#countainer_schools").children().length < 5) {
    var lastBlock = $("#countainer_schools .dynamic_section").last();
    var lastCounter = lastBlock.attr("data-counter");
    $("#countainer_schools").append(lastBlock.clone());
    var newBlock = $("#countainer_schools .dynamic_section").last();

    newBlock.find("input").val("");
    newBlock.find("select.user-school-id option").remove();

    var counter = lastCounter * 1 + 1;
    newBlock.attr("data-counter", counter);

    newBlock.find(".chosen-container").remove();
    newBlock.find(".user-school-nation").chosen();
    // newBlock.find('.user-school-nation').attr('name', 'user[custom_schools]['+ counter + '][state]');
    // newBlock.find('.user-school-region').attr('name', 'user[custom_schools]['+ counter + '][city]');
    // newBlock.find('.user-school-name').attr('name', 'user[custom_schools]['+ counter + '][name]');
    newBlock.find(".user-school-name").attr("id", "user_custom_schools_" + counter + "_name");
    newBlock.find(".user-school-region").attr("id", "user_custom_schools_" + counter + "_city");

    setAutocompleteRegion(newBlock.find('[data-purpose="fill-dropdown"]'));
    setAutocompleteSchool(newBlock.find('[data-purpose="auto-school"]'));
    $(".remove_school_link").css("display", "block");
  }
});

var removeSchool = (window.removeSchool = function (me) {
  if ($("#countainer_schools").children().length > 1) {
    $(me).closest(".dynamic_section").remove();
  }
  if ($("#countainer_schools").children().length == 1) {
    $(".remove_school_link").css("display", "none");
  }
});

window.fillSchools = function fillSchools(obj, region_id) {
  var schools = obj.parents(".school-name");
  if (region_id === undefined) {
    region_id = 0;
  }
  obj.find("option").filter(".deletable").remove();
  if (region_id) {
    var composedUrl = "/school_data/school_list_by_region_id?codice_scuola_parsed=false&region_id=" + region_id;
    $.ajax({
      url: composedUrl,
      dataType: "json",
      success: function (result) {
        if (result.length == 0) {
          // do nothing
          schools.hide();
        } else {
          $.each(result, function (key, value) {
            obj.prepend('<option class="deletable" value="' + value.school_code + '">' + value.name.replace(/"/g, "&quot;") + "</option>");
          });
          obj.prepend("<option class='deletable' selected></option>");
          schools.show();
        }
      },
    });
  }
};

window.fillRegions = function fillRegions(obj, nation) {
  var regions = obj.parents(".user-school-region-container");
  if (nation === undefined) {
    nation = "undefined";
  }
  obj.find("option").filter(".deletable").remove();
  var sanitizedNation = trim(nation);
  if (sanitizedNation && sanitizedNation !== "") {
    var composedUrl = "/school_data/regions_list_by_country_id?country_id=" + sanitizedNation;
    $.ajax({
      url: composedUrl,
      dataType: "json",
      success: function (result) {
        if (result.length == 0) {
          // do nothing
          regions.hide();
        } else {
          obj.append("<option></option>");
          $.each(result, function (key, value) {
            obj.append("<option class='deletable' value=" + value.id + ">" + value.region_name + "</option>");
          });
          regions.show();
        }
      },
    });
  }
};

window.setAutocompleteRegion = function setAutocompleteRegion(obj) {
  obj
    .autocomplete({
      minLength: 2,
      delay: 500,
      source: "/profile/city-list",
      source: function (request, response) {
        // request.term is the term searched for.
        // response is the callback function you must call to update the autocomplete's
        // suggestion list.
        var nation = $(obj).parents(".user-school-inputs").find(".user-school-nation").val();
        var params = { term: request.term };
        if (nation !== undefined) {
          params.nation = nation;
        }
        $.ajax({
          url: "/profile/city-list",
          data: params,
          dataType: "json",
          success: response,
          error: function () {
            response([]);
          },
        });
      },

      focus: function (event, ui) {
        obj.val(ui.item.label);
        return false;
      },

      select: function (event, ui) {
        obj.val(toTitleCase(ui.item.city));
        return false;
      },
    })
    .data("ui-autocomplete")._renderItem = function (ul, item) {
    return $("<li>")
      .append("<div><a>" + toTitleCase(item.city) + "</a></div></li>")
      .appendTo(ul);
  };
};

//  $(document).on('change focusout', '[data-purpose="fill-dropdown"]', function(e){

//   var input = $(this).closest('.dynamic_section').find('input')[1];
//   $.each($( '[data-purpose="auto-school"]' ), function(idx, v){
//     setAutocompleteSchool($(v));
//   });
// });

window.setAutocompleteSchool = function setAutocompleteSchool(obj) {
  var city = $(obj).parents(".user-school-inputs").find(".user-school-region").val();
  obj
    .autocomplete({
      minLength: 2,
      source: "/profile/school-list-by-name?city=" + city,

      focus: function (event, ui) {
        obj.val(ui.item.label);
        return false;
      },

      select: function (event, ui) {
        obj.val(toTitleCase(ui.item.name));
        $(obj).parents(".user-school-inputs").find(".user-school-id").val(ui.item.id);
        $(obj).parents(".user-school-inputs").find(".auto_mail").val(ui.item.email);
        $(".mail_miur").val("");
        return false;
      },
    })
    .data("ui-autocomplete")._renderItem = function (ul, item) {
    return $("<li>")
      .append("<div><a>" + toTitleCase(item.name) + "</a></div></li>")
      .appendTo(ul);
  };
};
// $(function() {
//   $.each($( '[data-purpose="fill-dropdown"]' ), function(idx, v){
//     setAutocompleteRegion($(v));
//   });
// });

// --- END school select functions ----
