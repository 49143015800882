$(function () {
  var viz;
  var div = document.getElementById('tableau-viz-cont');

  var options = {
    hideTabs: true,
    hideToolbar: true,
    width: "1016px",
    height: "964px"
  };

  window.createViz = function createViz(url) {
    if (!div) {
      return;
    }
    if (viz) {
      viz.dispose();
    }
    viz = new tableau.Viz(div, url, options);
  }

  window.select = function select($el) {
    var url = $el.attr('href');
    if (!viz || viz.getUrl() !== url) {
      createViz(url);
    }
  }

  var first = $('.tableau-wb-link').first();
  if (first) {
    select(first);
  }

  $('.tableau-wb-link').on('click', function(evt) {
    evt.preventDefault();
    select($(this));
  });

});
