$(function() {
  $("header.user-role-select-form .signup-steps input:submit[name!='commit']").on('click', function(event) {
    event.preventDefault();
    manageStepChange(this);
  });

  $('header.user-role-select-form .flat-box-steps-circle div').on('click', function() {
    manageStepCircles(this);
  });

  $("header.user-role-select-form .signup-steps input:submit[name='commit']").on('click', function(event) {
    event.preventDefault();
    $(this).closest('form').submit();
  });

  $('header.user-role-select-form input').on('change keyup', function() {
    manageInputChange(this);
  });

  // ---> START ---- role 1

  $('.step-1-role1 input').on('change keyup', function() {
    manageDisableBtn(this, 1);
  });

  $('.step-1-role1 select').on('change keyup', function() {
    manageDisableBtn(this, 1);
  });

  $('.step-1-role1 select').on('change keyup', function() {
    // $('.userbday-day').parsley().validate();
    // $('.userbday-month').parsley().validate();
    // $('.userbday-year').parsley().validate();
    manageDisableBtn(this, 1);
  });

  $('.step-2-role1 input').on('change keyup', function() {
    manageDisableBtn(this, 2);
  });

  $('.step-2-role1 select.user-school-nation').on('change keyup', function() {
    manageDisableBtn(this, 2);
  });

  $('.step-2-role1 select.user-school-id').on('change keyup', function() {
    manageDisableBtn(this, 2);
  });

  $('.step-3-role1 input').on('change keyup', function() {
    manageDisableBtn(this, 3);
  });

  // <--- END ---- role 1

  // ---> START ---- role 2

  $('.step-1-role2 input').on('change keyup', function() {
    manageDisableBtn(this, 1);
  });

  $('.step-1-role2 select').on('change keyup', function() {
    // $('.userbday-day').parsley().validate();
    // $('.userbday-month').parsley().validate();
    // $('.userbday-year').parsley().validate();
    manageDisableBtn(this, 1);
  });

  $('.step-2-role2 input').on('change keyup', function() {
    manageDisableBtn(this, 2);
  });

  $('.step-2-role2 select').on('change keyup', function() {
    manageDisableBtn(this, 2);
  });

  $('.step-3-role2 input').on('change keyup', function() {
    manageDisableBtn(this, 3);
  });

  $('.step-3-role2 select').on('change keyup', function() {
    // $('.parentbday-day').parsley().validate();
    // $('.parentbday-month').parsley().validate();
    // $('.parentbday-year').parsley().validate();
    manageDisableBtn(this, 3);
  });

  $('.step-4-role2 input').on('change keyup', function() {
    manageDisableBtn(this, 4);
  });

  // <--- END ---- role 2

  // ---> START ---- role 3

  $('.step-3-role3 input').on('change keyup', function() {
    manageDisableBtn(this, 3);
  });

  $('.step-1-role3 input').on('change keyup', function() {
      manageDisableBtn(this, 1);
    });
  $('.step-2-role3 input').on('change keyup', function() {
      manageDisableBtn(this, 2);
    });
  $('.step-1-role3 select').on('change keyup', function() {
      // $('.userbday-day').parsley().validate();
      // $('.userbday-month').parsley().validate();
      // $('.userbday-year').parsley().validate();
      manageDisableBtn(this, 1);
    });
  $('.step-2-role3 select').on('change keyup', function() {
      manageDisableBtn(this, 2);
    });

  // <--- END ---- role 3

  // required to avoid double-click on submit button
  $(".btn-signup-flow-last").one('click', function (event) {
    $(this).prop('disabled', true);
   });
  $(".btn-signup-flow-last").dblclick(function(e){
    e.preventDefault();
  });
});


// --- START login/sign-up flow functions ----

var getCurrentStep = window.getCurrentStep = function($this) {
  var classesParent = $($this).closest('.signup-steps').attr('class').split(' ');
  var curStep = classesParent[2].split('-')[1];
  return curStep;
};

var manageDisableBtn = window.manageDisableBtn = function($this, step) {
  var btn = $($this).closest('.signup-steps').find("input[type='submit']");
  var filled = $('#new_user').parsley({excluded: ""}).isValid({group: 'step_' + step});
  if (filled === true) {
    $(btn).prop('disabled', false);
  } else {
    $(btn).prop('disabled', true);
  }
};

var onPrivaciesChange = window.onPrivaciesChange = function($this, step) {
  if ($($this).is(':checked')) {
    $($this).attr('value', '1');
    $($this).attr('checked', true);
  } else {
    $($this).attr('value', '');
    $($this).attr('checked', null);
  }
  manageDisableBtn($this, step);
};

var dateSeparator = '/';

var correctCaptcha = window.correctCaptcha = function(response) {
  $('#recaptcha_status').val('1');
  var classesParent = $(".g-recaptcha").closest('.signup-steps').attr('class').split(' ');
  var curStep = classesParent[2].split('-')[1];
  manageDisableBtn(".g-recaptcha", curStep);
};

var manageStepCircles = window.manageStepCircles = function($this) {
  var currentIndex = $($this).siblings('.active').attr('class').split(' ')[0].split('-')[1];
  var selected = $($this).attr('class').split(' ')[0];
  var selectedIndex = selected.split('-')[1];
  if (selectedIndex*1 < currentIndex*1) {
    $('.flat-box.signup-steps').hide();
    $('.flat-box-header.signup-steps').hide();
    $('.flat-box-steps-circle').hide();
    $('.flat-box.signup-steps.'+ selected).show();
    $('.flat-box-header.signup-steps.'+ selected).show();
    $('.flat-box-steps-circle.'+selected).show();
    $("html, body").animate({ scrollTop: 0 }, "slow");
  }
};

var manageInputChange = window.manageInputChange = function($this) {
  var step = getCurrentStep($this);
  if ($($this).is(':checkbox')) {
    if ($($this).attr('id') == 'checkbox-form-tos_agreement') {
      $('.privacies input:checkbox').prop('checked', $($this).is(':checked'));
      $('.privacies input:checkbox').each(function() {
        onPrivaciesChange(this);
      });
    } else {
      $('#checkbox-form-tos_agreement').prop('checked', false);
      $('#checkbox-form-tos_agreement').attr('value', '');
    }
    onPrivaciesChange($this, step);
  }
};

var manageStepChange = window.manageStepChange = function($this) {
  var classesParent = $($this).closest('.signup-steps').attr('class').split(' ');
  var curStep = classesParent[2].split('-')[1];
  var curFlow = classesParent[2].split('-')[2]
  var nextStep = curStep*1+1;
  $('.signup-steps').hide();
  if ($('.step-'+ nextStep + '-' + curFlow).hasClass('skip-step')) {
    console.log('skip this step');
    nextStep = nextStep +1;
  }
  $('.step-'+ nextStep + '-' + curFlow).show();
  $("html, body").animate({ scrollTop: 0 }, "slow");
};

var gobackSignupFlow = window.gobackSignupFlow = function($this) {
  // event.preventDefault();
  var classesParent = $($this).closest('.signup-steps').attr('class').split(' ');
  var curStep = classesParent[2].split('-')[1];
  var curFlow = classesParent[2].split('-')[2]
  var nextStep = curStep*1-1;
  $('.signup-steps').hide();
  if ($('.step-'+ nextStep + '-' + curFlow).hasClass('skip-step')) {
    nextStep = nextStep - 1;
  }
  $('.step-'+ nextStep + '-' + curFlow).show();
  $("html, body").animate({ scrollTop: 0 }, "slow");
};

// --- START login/sign-up flow functions ----

