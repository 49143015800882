// Rails wraps input[type=checkbox] with div.field_with_errors tag
// this script exists to keep the same style of the clear form

(function () {
  $(function () {
    return $(".field_with_errors > input[type=checkbox]").change(function (event) {
      if (this.checked) {
        return $(".field_with_errors + label.checktype").addClass("checked");
      } else {
        return $(".field_with_errors + label.checktype").removeClass("checked");
      }
    });
  });
}.call(this));
